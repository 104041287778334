import { useEffect, useState } from "react";
import formData, { fileUploadFields, inputFields } from "./form-config";
import {
  fetchKeywordList,
  getMarketplaceCategories,
  getMarketplaceVendorData,
  submitMarketplaceForm,
  uploadMarketplaceFile,
} from "../../../apis/marketplace.api";
import { useHistory, useParams } from "react-router";
import {
  MPCheckboxGroup,
  MPFileUpload,
  MPFormGroup,
  MPServerErrorMessage,
  MPPriceInput,
  MPSelectInput,
  MPTextareaInput,
  MPTextInput,
  MPFilterKeywordInputSearchbox,
  MPMultiSelectAutocompleteChipTextField,
  MPSingleSelectAutocomplete,
  MPLogoUploadBlock,
} from "./form-components";
import { Avatar, Box, Button, Container, Typography } from "@mui/material";
import { IMAGES_PATH } from "../../../constants";
import {
  getCountries,
  getStateListFromCountry,
} from "../../../apis/common.api";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../../hooks/useForm";
import { ROUTE_PATHS } from "../../../routes/route-paths";
import ProfileCard from "../profile-card";
import { downloadFile, getGridCol, getNoOfEmpLabel, getPayload } from "../utils";

function MarketplaceForm() {
  const [isFileUpload, setIsFileUpload] = useState(true);
  const history = useHistory();
  const {
    handleInputChange,
    handleSubmit,
    formInputs,
    fieldConfig,
    submitting,
    runValidator,
    setFormData,
    handleInputFocus,
  } = useForm(formData);
  const [logo, setLogo]: any = useState({ preview: "", file: "" });
  const { initialListFetched } = useSelector((state: any) => state.UI);
  const [editMode, setEditMode] = useState(true);
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const params: any = useParams();
  const lists = useSelector((state: any) => state.common);
  const [serverError, setServerError] = useState(null);
  const [loading, setLoading] = useState(true);
  const id = params?.id;
  const [fileUploads, setFileUploads] = useState<{
    [key: string]: FileList | null;
  }>({
    onboardingGuide: null,
    permissions: null,
    agreement: null,
    technicalDocs: null,
    demoLink: null,
    presentationDeck: null,
  });
  const [oldFileUploads, setOldFileUploads] = useState<{
    [key: string]: FileList | null;
  }>({
    onboardingGuide: null,
    permissions: null,
    agreement: null,
    technicalDocs: null,
    demoLink: null,
    presentationDeck: null,
  });
  const [keywordList, setKeywordList] = useState([]);
  const errorObj = fieldConfig?.fields;

  useEffect(() => {
    initialListFetched && fetchMarketplaceInitialData();
  }, [initialListFetched]);

  useEffect(() => {
    fetchStateList(formInputs?.country);
  }, [formInputs?.country]);

  const fetchStateList = async (country) => {
    if (country) {
      await dispatch(getStateListFromCountry({ country }));
    }
  };

  const onEditModeToggle = () => {
    setEditMode(!editMode);
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    setFileUploads((prevUploads) => ({
      ...prevUploads,
      [name]: files,
    }));
  };

  const updateSetFormData = (data) => {
    const marketplaceData = {
      ...data,
      no_of_emp: lists?.noOfEmp?.data?.find(
        (ne) => ne?.label === data?.no_of_emp
      )?.id,
    };

    setIsFileUpload(!data?.hasDemoLink);

    setFormData(marketplaceData);
  };

  const fetchMarketplaceInitialData = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(getMarketplaceCategories()),
      dispatch(getCountries()),
    ]);

    if (id) {
      const response: any = await dispatch(getMarketplaceVendorData(id));

      if (response.success) {
        updateSetFormData(response?.data);
        const fileLinks = {
          onboardingGuide: response?.data?.onboardingGuide,
          permissions: response?.data?.permissions,
          agreement: response?.data?.agreement,
          technicalDocs: response?.data?.technicalDocs,
          demoLink: response?.data?.demoLink,
          presentationDeck: response?.data?.presentationDeck,
        };
        setFileUploads({ ...fileLinks });
        setOldFileUploads({ ...fileLinks });
        setLogo({
          ...logo,
          file: response?.data?.logo,
          preview: response?.data?.logo,
        });
      }
    }
    setLoading(false);
  };

  const onVendorMarketplaceFormSubmitSuccess = async (res) => {
    const resId = res?.id;
    const logoUploadData = new FormData();
    const onboardingGuide = new FormData();
    const permissions = new FormData();
    const agreement = new FormData();
    const technicalDocs = new FormData();
    const demoLink = new FormData();
    const presentationDeck = new FormData();

    const fileMapping = [
      { key: "logo", data: logoUploadData, file: logo?.file },
      {
        key: "onboarding-guide",
        data: onboardingGuide,
        file: fileUploads?.onboardingGuide?.[0],
      },
      {
        key: "permissions",
        data: permissions,
        file: fileUploads?.permissions?.[0],
      },
      { key: "agreement", data: agreement, file: fileUploads?.agreement?.[0] },
      {
        key: "technical-docs",
        data: technicalDocs,
        file: fileUploads?.technicalDocs?.[0],
      },
      { key: "demo-link", data: demoLink, file: fileUploads?.demoLink?.[0] },
      {
        key: "presentation-deck",
        data: presentationDeck,
        file: fileUploads?.presentationDeck?.[0],
      },
    ];

    fileMapping.forEach(({ key, data, file }) => {
      if (file instanceof File) {
        data.append("file", file, file?.name);
      }
    });

    setUploading(true);

    const uploadPromises = fileMapping
      .filter(({ file }) => file instanceof File)
      .map(({ key, data }) =>
        dispatch(uploadMarketplaceFile(resId, key, data))
      );

    Promise.all(uploadPromises).finally(() => {
      setUploading(false);
      history.push(ROUTE_PATHS.MARKETPLACE.LIST);
    });
  };

  const onVendorMarketplaceFormSubmitError = (error) => {
    setServerError(error);
  };

  const onSubmit = async (e) => {
    runValidator(e, (res) => {
      if (res.isValid) {
        onSubmitConfirm();
      } else {
        document.getElementById("marketplace-form").scrollIntoView({
          behavior: "smooth",
        });
      }
    });
  };

  const handleAvatarUpload = (file) => {
    setLogo({ ...logo, file, preview: URL.createObjectURL(file) });
  };

  const onSubmitConfirm = () => {
    const payload = getPayload({ formInputs, lists });

    handleSubmit(
      null,
      submitMarketplaceForm(id, payload),
      onVendorMarketplaceFormSubmitSuccess,
      onVendorMarketplaceFormSubmitError
    );
  };

  const handleKeywordSearch = async (e) => {
    const response: any = await dispatch(fetchKeywordList(e.target.value));

    if (response?.data) {
      setKeywordList(response.data);
    }
  };

  const handleCancel = () => {
    window.location.href = ROUTE_PATHS.MARKETPLACE.LIST;
  };

  const renderInput = (field) => {
    const fieldData = fieldConfig?.fields[field];

    const renderInputComponent = () => {
      switch (fieldData.type) {
        case "async_input_searchbox":
          return (
            <MPFilterKeywordInputSearchbox
              name={"keywords"}
              options={keywordList?.map((item) => {
                return item?.label;
              })}
              value={formInputs.keywords}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onInputChange={handleKeywordSearch}
              error={errorObj?.keywords?.error}
            />
          );
        case "textarea":
          return (
            <MPTextareaInput
              name={field}
              value={formInputs?.[field]}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          );
        case "autocomplete_single_select":
          if (lists?.[fieldData?.list_key]?.data?.length === 0) {
            return (
              <MPTextInput
                name={field}
                value={formInputs?.[field]}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            );
          }
          return (
            <MPSingleSelectAutocomplete
              name={field}
              options={lists?.[fieldData?.list_key]?.data || []}
              value={formInputs?.[field]}
              valueKey={fieldData?.valueKey}
              labelKey={fieldData?.labelKey}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              error={errorObj?.[field]?.error}
            />
          );
        case "select":
          return (
            <MPSelectInput
              name={field}
              options={lists?.[fieldData?.list_key]?.data ?? []}
              value={formInputs?.[field]}
              valueKey={fieldData?.valueKey}
              labelKey={fieldData?.labelKey}
              onChange={handleInputChange}
              flatList={fieldData?.flatList ?? false}
              onFocus={handleInputFocus}
            />
          );
        case "multi_select":
          return (
            <MPCheckboxGroup
              name={field}
              options={lists?.[fieldData?.list_key]?.data ?? []}
              value={formInputs?.[field]}
              valueKey={fieldData?.valueKey}
              labelKey={fieldData?.labelKey}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          );
        case "amount":
          return (
            <>
              <span className="prefix">$</span>
              <MPPriceInput
                name={field}
                value={formInputs?.[field]}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </>
          );
        case "multi_select_autocomplete_chip_textfield":
          return (
            <MPMultiSelectAutocompleteChipTextField
              name={field}
              options={
                fieldData?.freeSolo ? [] : lists?.[fieldData?.list_key]?.data ?? []
              }
              value={formInputs?.[field]}
              onChange={handleInputChange}
              freeSolo={fieldData?.freeSolo}
              placeholder={fieldData?.placeholder}
            />
          );
        default:
          return (
            <MPTextInput
              name={field}
              value={formInputs?.[field]}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          );
      }
    };

    return (
      <MPFormGroup label={fieldData?.label} error={errorObj?.[field]?.error}>
        {renderInputComponent()}
      </MPFormGroup>
    );
  };

  const rednerFileUpload = ({ name, label }) => {
    return (
      <MPFormGroup label={label}>
        <MPFileUpload name={name} onChange={handleFileChange} />
        {oldFileUploads[name] && (
          <Box display={"flex"} alignItems={"center"} gap={1} mt={1}>
            <Typography variant="body2" color={"grey"}>
              File you uploaded recently:
            </Typography>
            <a
              href="#"
              onClick={(e) => handleFileDownload(e, oldFileUploads[name])}
              style={{ textDecoration: "underline", marginTop: 3 }}
            >
              <Typography variant="body2" color={"blue"}>
                View File
              </Typography>
            </a>
          </Box>
        )}
      </MPFormGroup>
    );
  };

  if (loading) {
    return null;
  }

  if (!editMode) {
    return (
      <Container maxWidth="lg">
        <div className="form--section">
          <Box display={"flex"} mr={3}>
            <EditModeButton
              editMode={editMode}
              onEditModeToggle={onEditModeToggle}
            />
          </Box>

          <ProfileCard
            showEditBtn
            showShareBtn={false}
            data={{
              ...formInputs,
              logo: logo?.preview,
              no_of_emp: getNoOfEmpLabel(
                lists?.noOfEmp?.data,
                formInputs?.no_of_emp
              ),
            }}
            fileUploads={fileUploads}
            logo={logo?.preview}
            showViewCount={false}
          />
        </div>
      </Container>
    );
  }

  const renderFileUploadFields = fileUploadFields.map((field) => (
    <div className="col-12" key={field.name}>
      {rednerFileUpload({
        label: field.label,
        name: field.name,
      })}
    </div>
  ));

  const renderFormInputs = inputFields.map((field) => (
    <div className={field.size} key={field.name}>
      {renderInput(field.name)}
    </div>
  ));

  const renderLogoUpload = (
    <div className={`col-${getGridCol({ xs: 12, sm: 6 })}`}>
      <div className="form-grp">
        <MPLogoUploadBlock logo={logo?.preview} onUpload={handleAvatarUpload} />
      </div>
    </div>
  );

  const renderActionButtons = (
    <div className="col-12">
      <div
        className="vf-form-btn"
        style={{ display: "flex", justifyContent: "center", gap: 10 }}
      >
        <button
          type="submit"
          disabled={submitting || uploading}
          className="btn"
        >
          {submitting || uploading ? "Please wait..." : "Submit"}
        </button>
        <button
          type="button"
          onClick={handleCancel}
          className="btn"
          style={{
            backgroundColor: "grey",
            color: "white",
            border: "1px solid grey",
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );

  const handleFileDownload = (e, file) => {
    e.preventDefault();

    downloadFile(file);
  };

  return (
    <div className="form--section" id="marketplace-form">
      <div
        className="loader-block"
        style={{ display: submitting || uploading ? "" : "none" }}
      >
        <img src={`${IMAGES_PATH}/loader.svg`} />
        <label>Please wait while your request is being proceed...</label>
      </div>

      <div className="container">
        <EditModeButton
          editMode={editMode}
          onEditModeToggle={onEditModeToggle}
        />

        {serverError && <MPServerErrorMessage error={serverError} />}

        <form onSubmit={onSubmit} noValidate>
          <div className="col-row">
            {renderLogoUpload}
            {renderFormInputs}
            {renderFileUploadFields}
            {renderActionButtons}
          </div>
        </form>
      </div>
    </div>
  );
}

function EditModeButton({ editMode, onEditModeToggle }) {
  return (
    <div className="preview-button-block">
      <button type="button" className="btn" onClick={onEditModeToggle}>
        {editMode ? "PREVIEW" : "EDIT"}
      </button>
    </div>
  );
}

export default MarketplaceForm;
