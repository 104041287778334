import { IconButton, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { InitialNameAvatar } from "../../../../components/images/initial-name-avatar";
import { SimpleTable } from "../../../../components/mui/table";
import { RENDERER_TYPES } from "../../../../enums";
import { ROUTE_PATHS } from "../../../../routes/route-paths";
import {
  convertToFullName,
  convertToUrl,
  getConvertedExternalUrl,
} from "../../../../services/utils";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function ClientRequestListTable({
  list,
  columns,
  onRowSelect,
  selectedRows,
  onApproveClick,
  onRejectClick,
}: any) {
  const handleTDRenderer = (row, column) => {
    switch (column.rendererType) {
      case RENDERER_TYPES.CLICKABLE_FULL_NAME_WITH_AVATAR:
        return (
          <Link
            to={convertToUrl(ROUTE_PATHS.CLIENTS.DETAILS, {
              ":id": row?.id,
              ":tab" : "info"
            })}
            style={{textDecoration: "none"}}
          >
            <InitialNameAvatar
              text={convertToFullName(
                row?.firstName,
                row?.lastName
              )}
              name={row?.firstName}
              image
              src={row?.picture}
            />
          </Link>
        );
      case RENDERER_TYPES.NEW_TAB_ROUTE_LINK:
        return (
          <Link
            to={{
              pathname: getConvertedExternalUrl(row?.[column.field]),
            }}
            target="_blank"
            title="Linkedin URL"
          >
            <LinkedInIcon />
          </Link>
        );
      case RENDERER_TYPES.ACTION:
        return (
          <Stack gap={0.5} direction="row">
            <IconButton
              key={row.id}
              sx={{ p: 0 }}
              title="Approve"
              onClick={() => onApproveClick(row.id)}
            >
              <CheckCircleOutlineOutlinedIcon color="success" />
            </IconButton>
            <IconButton
              aria-owns="client-request-list-action-items"
              aria-haspopup="true"
              key={row.id}
              sx={{ p: 0 }}
              title="Reject"
              onClick={() => onRejectClick(row.id)}
            >
              <HighlightOffIcon color="error" />
            </IconButton>
          </Stack>
        );
      default:
        break;
    }
  };

  return (
    <>
      <SimpleTable
        tdRenderer={handleTDRenderer}
        rows={list?.data}
        columns={columns}
        total={list?.total}
        fetching={list?.loading}
        onRowSelect={onRowSelect}
        selectedRows={selectedRows}
        showPagination={false}
      />
    </>
  );
}

export { ClientRequestListTable };
