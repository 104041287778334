import { RENDERER_TYPES } from "../../../../enums";

const CLIENT_REQUEST_LIST_COLUMNS = [
  {
    title: "Name",
    field: "firstName",
    dataType: "string",
    rendererType: RENDERER_TYPES.CLICKABLE_FULL_NAME_WITH_AVATAR,
    width: "20%",
  },
  { title: "Email", field: "email", dataType: "string" },
  { title: "Title", field: "title", dataType: "string" },
  { title: "Company", field: "company", dataType: "string" },
  {
    title: "LinkedIn",
    field: "linkedIn",
    dataType: "string",
    rendererType: RENDERER_TYPES.NEW_TAB_ROUTE_LINK,
  },
  { title: "Action", rendererType: RENDERER_TYPES.ACTION },
];

const CLIENT_REQUEST_LIST_ROWS = [
  {
    id: "63ddd1e9b2963cd8335ec0f3",
    firstName: "Nirav",
    lastName: "Parekh",
    email: "nirav55.parekh@gmail.com",
  },
];

export { CLIENT_REQUEST_LIST_COLUMNS, CLIENT_REQUEST_LIST_ROWS };
