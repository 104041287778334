import store from "../redux";
import { setShowNotifications } from "../redux/modules/UI/actions";
import * as R from "ramda";
import { ENUM_CURRENCY, ENUM_MEETING_STATUS } from "../enums";
import {
  CONST_CURRENCY_LIST,
  CONST_MEETING_STATUS_LIST,
  FILE_TYPE_ICONS,
} from "../constants/lists";
import * as _ from "lodash";
import { DATE_FORMATS, formatDate } from "./date-and-time.utils";
import { DASHBOARD_SUMMARY } from "../screens/dashboard/summary-blocks/summary-list";

const fileUploadTypes = [
  { type: "onboardingGuide", label: "Implementation steps/timeline" },
  { type: "permissions", label: "Details on requirements for a pilot/POC" },
  {
    type: "agreement",
    label: "Standard or proposed contract/licensing/subscription agreement",
  },
  {
    type: "technicalDocs",
    label:
      "Technical Documentation Access: Providing access to technical documentation",
  },
  {
    type: "demoLink",
    label:
      "Personal gmail account try out a demo or trial instance in the Cloud",
  },
  {
    type: "presentationDeck",
    label: "Presentation/deck/materials as a pre-read",
  },
];

export function showNotification({
  show = true,
  message = "",
  type = "success",
  closeOnClick = false,
  timeout = 5000,
}: any) {
  store.dispatch(
    setShowNotifications({
      type,
      message,
      show,
      timeout,
      closeOnClick,
    })
  );
}

export function clearNotification() {
  store.dispatch(
    setShowNotifications({
      type: "",
      message: "",
      show: false,
      closeOnClick: false,
      timeout: 5000,
    })
  );
}

export function routeNavigation(router, route) {
  const url = `#/${route}`;
  router.props.history.push(route);
}

export function getStepProgress(noOfSteps, currentStep) {
  return (noOfSteps / currentStep) * 100;
}

export function numberFormat(number, currency = "") {
  if (!number) number = 0;

  if (!currency) return new Intl.NumberFormat().format(number);

  if (currency)
    return new Intl.NumberFormat(CONST_CURRENCY_LIST[currency].intl, {
      style: "currency",
      currency,
    }).format(number);
}

export function convertToUrl(url, params) {
  let str = url;

  Object.keys(params).map((param) => {
    const replaceItem = params[param];

    str = str.replace(param, replaceItem);
  });

  return str;
}

/**
 * getResErrorMsg :: AxiosResponse -> String,
 * AxiosResponse = Object
 */
export const getResErrorMsg = (error) => {
  let message = R.pathOr(false, ["response", "data", "message"], error);
  const responseStatus = R.pathOr(false, ["response", "status"])(error);

  if (message) {
    return { message, responseStatus };
  }

  message = R.pathOr(R.pathOr("Unknown server error", ["message"], error), [
    "response",
    "data",
    "error",
    "message",
  ])(error);

  if (message === "jwt malformed") {
    return "you were logged out";
  }

  if (message === "Network Error")
    return "Something went wrong with connection";

  if (responseStatus > 400) {
    if (
      responseStatus === 404 &&
      message !== "Request failed with status code 404"
    )
      return "Not found it";
    if (responseStatus === 400) return "Bad request, try to inform developers";
  }

  return { message, responseStatus };
};

export const getMeetingStatus = (data) => {
  if (data?.isCompleted) return ENUM_MEETING_STATUS.DONE;
  if (data?.isRejected) return ENUM_MEETING_STATUS.REJECTED;

  return ENUM_MEETING_STATUS.PENDING;
};

export const getInvitationStatus = (data) => {
  return data?.invitationStatus;
};
export const getProjectStatus = (data) => {
  return data?.invitationStatus || data?.status;
};

export const getPaymentStatus = (data) => {
  return data.status;
};

export const convertToFullName = (firstName, lastName) => {
  return `${firstName || ""} ${lastName || ""}`;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function decimalSeperatorForEmployee(value) {
  if (!value) return false;
  if (!isNaN(value)) return numberWithCommas(value);
  if (value === "10001+") {
    return "10,001+";
  }
  const values = value?.split("-");
  let valuechanged = false;
  if (values[0] && values[0].trim().length > 3) {
    values[0] = numberWithCommas(values[0]);
    valuechanged = true;
  }
  if (values[1] && values[1].trim().length > 3) {
    values[1] = numberWithCommas(values[1]);
    valuechanged = true;
  }
  if (valuechanged) {
    return `${values[0]} - ${values[1]}`;
  } else {
    return value;
  }
}

export function createDashboardEvents(responseData) {
  return responseData?.value?.map((event) => {
    const status = getMeetingStatus(event);

    return {
      ...event,
      iId: event.id,
      title: `${formatDate(
        event.selectedTimeSlot.from,
        DATE_FORMATS.hourWithAMPM
      )} Meeting`,
      date: `${formatDate(event.selectedTimeSlot.from, DATE_FORMATS.yyymmdd)}`,
      datetime: `${formatDate(
        event.selectedTimeSlot.from,
        DATE_FORMATS.fullDateWithTime
      )} - ${formatDate(
        event.selectedTimeSlot.to,
        DATE_FORMATS.hourMinWithAMPM
      )}`,
      color: CONST_MEETING_STATUS_LIST[status]?.eventBgColor,
    };
  });
}

export function createDashboardSummary(responseData) {
  let summaryData = [];

  responseData.value.forEach((res, index) => {
    summaryData.push({
      icon: DASHBOARD_SUMMARY[index].icon,
      label: DASHBOARD_SUMMARY[index].label,
      rendererType: DASHBOARD_SUMMARY[index].rendererType,
      ...(res.status === "fulfilled" && { ...res.value.data }),
    });
  });

  return summaryData;
}

export function getPercentageIncreaseOrDescrease(newCount, oldCount) {
  if (newCount < oldCount || newCount === oldCount) return 0;
  const symbol = newCount === oldCount ? "" : newCount > oldCount ? "+" : "-";
  if (!oldCount && !newCount) return `${symbol}${0}`;

  if (!oldCount) return `${symbol}${100}`;

  if (!newCount) return `${symbol}${oldCount}`;

  return `${symbol}${Math.abs(
    Number(((newCount - oldCount) / oldCount) * 100)
  )?.toFixed(2)}`;
}

export function getConvertedExternalUrl(url) {
  if (!url) return "";

  if (url?.match(/https:\/\//i)) {
    return url;
  }

  return `https://${url}`;
}

export function convertToHTTPSURL(url) {
  if (!url?.startsWith("http://") && !url?.startsWith("https://")) {
    url = "https://" + url;
  }
  return url;
}

export function getFileTypeIcon(file) {
  if (!file) return FILE_TYPE_ICONS.default;

  if (file?.[0] instanceof File) {
    if (!file[0]) return FILE_TYPE_ICONS.default;
    const tFile = file[0];
    const fileType = tFile?.name?.split(".")?.pop();
    return FILE_TYPE_ICONS?.[fileType] || FILE_TYPE_ICONS?.default;
  } else {
    return FILE_TYPE_ICONS?.default;
  }
}

export function getFileName(fileName) {
  return (
    fileUploadTypes?.find((fut) => fut?.type === fileName)?.label || fileName
  );
}

export function amountFormat(number, options = {}) {
  if (!number) number = 0;

  return new Intl.NumberFormat("en-US", {
    currency: ENUM_CURRENCY.USD,
    style: "currency",
    maximumFractionDigits: 0,
    signDisplay: "never",
    ...options,
  })?.format(number);
}

export function formatNumberWithCommas(input: string | number) {
  const number = String(input).split(".")[0]; // Ensure input is treated as a string and strip decimal part
  const [integerPart] = number.split(".");

  // Format the integer part with commas in the standard number format
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  return formattedIntegerPart;
}

export function getLast100Years() {
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i <= 100; i++) {
    years.push(currentYear - i);
  }

  return years;
}

export function trucatedText(text, maxLength = 100) {
  return text.length > maxLength ? `${text?.substring(0, maxLength)}...` : text;
}

export function checkIsNoInitialConfigRequiredRoutes() {
  const noInitialConfigRequiredRoutes = ["review-report"];
  let isNoInitialConfigRequiredRoutes = false;

  for (const route of noInitialConfigRequiredRoutes) {
    if (
      window &&
      window.location.pathname.startsWith(`/admin-portal/${route}`)
    ) {
      isNoInitialConfigRequiredRoutes = true;
      break;
    }
  }

  return isNoInitialConfigRequiredRoutes;
}
